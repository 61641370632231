// 作品展示
<template>
    <div class="view-list cities">
        <div class="list-content">
            <div class="list-nav">
                <div class="sort-filter">
<!--                    <span @click="showSort = true">{{sortText}}</span>-->
<!--                    <img src="../static/image/icon/icon_jtb.png">-->
                </div>
                <div class="type-filter">
                    <span @click="showStreet = true">{{areaValue}}</span>
                    <van-icon class="filter-icon" name="filter-o" />
<!--                    <img src="../static/image/icon/icon_cf.png">-->
                </div>

            </div>
            <div class="works-list">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <div class="works-item" v-for="(item, index) in listData" :key="index" @click="toDetail(item)">
                        <div class="item-image-box">
                            <img class="item-image" :src="item.CoverURL"/>
                            <div class="icon-play-box">
                                <img class="icon-play" src="../static/image/icon/icon_spbf.png"/>
                            </div>
                            <div class="item-time">
                                <span>{{ item.Duration }}</span>
                            </div>
                        </div>
                        <div class="item-info-box">
                            <div class="item-title">{{ item.Name }}</div>
                            <div class="item-info-other">
                                <div class="item-author">
                                    <img src="../static/image/icon/icon_hy.png"/>
                                    <span>{{item.UserName}}</span>
                                </div>
<!--                                <div class="item-agree">-->
<!--                                    <img src="../static/image/icon/icon_70_ydz.png"/>-->
<!--                                    <span>{{item.VoteNum}}</span>-->
<!--                                </div>-->
                                <!--                                <div class="item-share">-->
                                <!--                                    <img src="../static/image/icon/icon_70_fx.png"/>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>

            <van-popup v-model="showStreet" round position="bottom">
                <van-cascader
                        v-model="streetValue"
                        title="请选择所在地区"
                        :options="options.street"
                        :field-names="{ text: 'Name', value: 'Id',children: 'children'}"
                        @close="showStreet = false"

                        @finish="subStreet"
                />
<!--                @change="changeArea"      查找街道-->
            </van-popup>
            <van-popup v-model="showSort" round position="bottom">
                <van-picker
                        title=" "
                        show-toolbar
                        :columns="options.sort"
                        @confirm="confirmSort"
                        @cancel="showSort = false"
                />
            </van-popup>
        </div>
        <Back position="top" urlName="Home"></Back>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {List, Cascader, Toast} from 'vant';
    import {getArea, getStreet, votePageList} from '../server'
    import getStrParams from "../utils/getStrParams";
    import core from '../core/index'
    import Back from "../components/Back";
    import initWXAPI from "../utils/initWXAPI";
    import WXShare from "../utils/WXShare";

    Vue.use(Cascader);
    Vue.use(List);
    export default {
        name: "List",
        components: {Back},
        data() {
            return {
                sessionId: '',
                finished: false,
                loading: false,
                listData: [],
                showStreet: false,
                showSort: false,
                sortText: '默认排序',
                areaValue: '全部地区',
                streetValue: '',
                params: {
                    CateId: '1',
                    SessionId: '',
                    sortsTypes: '',
                    strdistrictid: '',
                    strsubdistrictid: ''
                },
                page: 1,
                limit: 8,
                options: {
                    street: [],
                    sort: [
                        {text: '默认排序', value: ''},
                        {text: '首字母', value: '1'},
                        {text: '时间排序', value: '2'},
                        {text: '点赞数量', value: '3'}
                    ]
                },
                nav: {
                    worksType: '全部作品',
                    area: '区域'
                }
            }
        },
        watch: {
            params: {
                handler() {
                    this.page = 1;
                    this.listData = [];
                    this.getListData()
                },
                deep: true
            }
        },
        methods: {
            onLoad() {
                this.page++
                this.getListData();
            },
            confirmSort(value) {
                this.sortText = value.text
                this.params.sortsTypes = value.value
                this.showSort = false;
            },
            resetFilter() {
            },
            subFilter() {
            },
            // 改变区域，刷新街道列表
            async changeArea(item) {
                if (!item.value) return;
                const data = await getStreet({DistrictId: item.value})
                this.options.street.filter(d => item.value === d.Id ? d.children = data.Data.list : '')
            },
            subStreet({selectedOptions}) {
                this.showStreet = false;
                this.areaValue = selectedOptions.map((option) => option.Name).join('/');
                this.params.strdistrictid = selectedOptions[0].Id || '';
                this.params.strsubdistrictid = selectedOptions[1] && selectedOptions[1].Id || '';
            },
            toDetail(item) {
                this.$router.push({path: `detail/${item.Id}`})
            },
            async getListData() {
                this.loading = true;
                const data = await votePageList(Object.assign({}, this.params, {page: this.page, limit: this.limit}))
                this.loading = false;
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                    return;
                }
                const _data = data.Data;
                if (_data.list) {
                    this.finished = !!(_data.list.length < this.limit)
                    if (this.listData.length) {
                        this.listData = [...this.listData, ..._data.list]
                    } else {
                        this.listData = _data.list
                    }
                }
            },

            async getAreaOptions() {
                const data = await getArea({})
                // if (data.Data.list.length && this.params.CateId === '1') {
                //     data.Data.list.map(d => {
                //         d.children = []
                //     })
                // }
                data.Data.list.unshift({Id: '', Name: '全部地区'})
                this.options.street = data.Data.list;
            },
            initPageShare() {
                // window.location.href.split(window.location.host)[1]
                initWXAPI.init(this.$store.state.url)
                const options = {
                    title: '红色故事演讲大赛',
                    desc:  '2022上海市民文化节',
                    imgUrl: 'https://redsw1.02lu.com/share_logo.png',
                    link: window.location.href
                    // link: window.location.href.split(window.location.host)[1]
                }
                WXShare.initWXShare(options)
                WXShare.initWXTimelineShare(options)
            }
        },
        created() {
            // this.params.CateId = getStrParams.getStrParams(window.location.href.split('?')[1]).type || '1'
            this.params.SessionId = core.cookie.getStorage('sessionId')
            this.getAreaOptions()
            this.initPageShare()
           // this.getListData()
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/common";

    .view-list {
        background: url("../static/image/ny_bg.png") no-repeat top left #be0b15;
        background-size: contain;
        padding-top: toRem(400);
        &.cities{
            background-image: url("../static/image/ny_bg.png");
        }
        .list-content {
            .list-nav {
                display: flex;
                height: toRem(60);
                line-height: toRem(54);
                padding: 0 toRem(20);
                background: rgba(0, 0, 0, .3);
                color: #B2B2B2;
                font-size: toRem(26);

                span, img {
                    vertical-align: middle;
                }

                img {
                    width: toRem(30);
                    margin-left: toRem(12);
                }

                .type-filter {
                    width: toRem(260);
                    text-align: right;
                    color: #fff;
                    font-size: toRem(26);
                    *{
                        vertical-align: middle;
                    }
                }

                .sort-filter {
                    flex: 1;
                }
            }

            .works-list {
                padding: toRem(20);
                min-height: calc(100vh - 17rem)

            }
        }
    }

    .type-popup {
        .filter-content {
            max-height: toRem(700);
            overflow-y: auto;

            .filter-item {
                .filter-title {
                    padding: toRem(20);
                }

                .item-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    background: #f8f8f8;
                    padding: toRem(10);

                    li {
                        width: 28%;
                        text-align: center;
                        height: toRem(50);
                        line-height: toRem(50);
                        border: 1px solid #ddd;
                        border-radius: toRem(10);
                        color: #999;
                        margin: toRem(10);
                        font-size: toRem(22);
                        background: #fff;
                    }
                }

                &.street .item-list li {
                    width: 100%;
                }
            }
        }

        .bottom-btn-box {
            display: flex;

            .reset-btn, .sub-btn {
                flex: 1;
                text-align: center;
                height: toRem(100);
                line-height: toRem(100);
                font-size: toRem(32);
                border-top: 1px solid #ccc;
                color: #999;
            }

            .sub-btn {
                background: #A40E14;
                color: #fff;
                border-color: #A40E14;
            }

        }
    }
</style>

<!--<van-popup v-model="showStreet" round position="bottom" class="type-popup">-->
<!--    <div class="filter-content">-->
<!--        <div class="filter-item type">-->
<!--            <div class="filter-title">作品分类</div>-->
<!--            <ul class="item-list">-->
<!--                <li class="" v-for="(item, index) in options.type" :key="index">{{item.text}}</li>-->
<!--            </ul>-->
<!--        </div>-->
<!--        <div class="filter-item area">-->
<!--            <div class="filter-title">所在地区</div>-->
<!--            <ul class="item-list">-->
<!--                <li class="" v-for="(item, index) in options.area" :key="index">{{item.text}}</li>-->
<!--            </ul>-->
<!--        </div>-->
<!--        <div class="filter-item street" v-if="options.street">-->
<!--            <div class="filter-title">所在街道</div>-->
<!--            <ul class="item-list">-->
<!--                <li class="" v-for="(item, index) in options.street" :key="index">{{item.text}}</li>-->
<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="bottom-btn-box">-->
<!--        <div class="reset-btn" @click="resetFilter">重置</div>-->
<!--        <div class="sub-btn" @clcik="subFilter">确定</div>-->
<!--    </div>-->
<!--</van-popup>-->